#footer {
  width: 100%;
}

.footer-row {
  padding: 75px 0;
  border-top: 1px solid #8a8a8a;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
}

footer#footer .footer-row > div {
  width: 25%;
  display: flex;
  flex-direction: column;
}

footer#footer .footer-row h3 {
  margin: 0;
}

footer#footer .contact-info p {
  width: 100%;
  padding-right: 0;
  line-height: 25px;
}

footer#footer ul {
  margin-top: 10px;
  padding-top: 2px;
  padding-bottom: 3px;
  line-height: 25px;
}

footer#footer ul > li {
  list-style-type: none;
  font-weight: 500;
  color: #878a8f;
  width: 100%;
  font-size: 80%;
  line-height: 25px;
}

footer#footer ul > li span {
  color: var(--blue);
}

footer#footer h3 + p {
  margin-top: 10px;
  font-size: 80%;
}
footer#footer .footer-row .footer-logo {
  align-items: center;
  width: 50%;
}

footer#footer .footer-row .footer-logo a {
  max-width: 200px;
  cursor: pointer;
  display: inline-flex;
}

footer#footer .footer-row .footer-logo a svg {
  width: 100%;
  height: 100%;
  transition: 0.3s all;
  fill: var(--blue);
}

footer#footer .footer-row .latest-projects {
  display: flex;
  align-items: flex-end;
}

footer#footer .footer-row .copyright {
  width: 100%;
}

@media (max-width: 1024px) {
  footer#footer .footer-row .footer-logo a {
    max-width: 160px;
  }
  footer#footer .footer-row .footer-logo {
    width: 33.33%;
  }
  footer#footer .footer-row > div {
    width: 33.33%;
  }
}

@media (max-width: 767px) {
  footer#footer .footer-row h3 br {
    display: none;
  }
  .footer-row {
    display: flex;
    flex-direction: column;
    padding: 45px 0px 35px;
  }

  footer#footer .footer-row > div {
    width: 100% !important;
    padding-bottom: 20px;
  }

  footer#footer .footer-row .latest-projects {
    align-items: flex-start;
    order: 3;
  }

  footer#footer .footer-row .contact-info {
    order: 2;
  }

  footer#footer .footer-row .footer-logo {
    order: 1;
  }
}
