.content-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.home-loader {
  position: fixed;
  width: 100vw;
  overflow: hidden;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-loader::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform: translate(-50%, -50%);
  background: var(--blue);
  width: 100vw;
  overflow: hidden;
  height: 100%;
  animation: TopTransition 5s;
}

@keyframes TopTransition {
  0% {
    transform: translateY(100%);
  }
  17% {
    transform: translateY(0%);
  }
  25% {
    transform: translateY(0%);
  }
  32% {
    transform: translateY(0%);
  }
  45% {
    transform: translateY(-100%);
  }
  75% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(-100%);
  }
}

.home-loader .bounce-icon {
  display: inline-flex;
  animation: ballmove 2.6s;
  transition: 0.4s all;
}

.loader-text {
  position: absolute;
  left: 60px;
  bottom: 50px;
  animation: textmove 2.4s;
  text-align: left;
  opacity: 0;
  width: 100%;
}

@keyframes ballmove {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes textmove {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.loader-text h5 {
  font-size: 80%;
  text-transform: uppercase;
  padding-top: 5px;
  padding-bottom: 8px;
  color: var(--loaderText);
  font-weight: 400;
  letter-spacing: 5px;
}

.loader-text h1 {
  margin-top: 15px;
  font-size: 360%;
  padding-top: 1px;
  padding-bottom: 4px;
  color: var(--loaderText);
}
@media (max-width: 767px) {
  .loader-text {
    position: absolute;
    left: 20px;
    bottom: 40px;
  }

  .loader-text h1 {
    margin-top: 5px;
    font-size: 160%;
  }
}
