button.up-button {
  /* background: rgba(15, 20, 30, 0.3); */
  background: var(--blue);
  border-radius: 100px;
  border: 2px solid var(--blue);
  width: 46px;
  height: 46px;
  z-index: 900;
  box-shadow: none;
}

button.up-button svg {
  width: 20px;
  height: 20px;
  pointer-events: none;
  fill: var(--white);
}

button.up-button:hover {
  background: var(--blue);
}

button.up-button:hover svg {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: scrollUp;
  animation-name: scrollUp;
}

@keyframes scrollUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    opacity: 1;
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
}

@media (max-width: 767px) {
  button.up-button {
    box-shadow: none;
    border: none;
    opacity: 0.6;
    right: 20px;
    bottom: 30px;
  }
}
