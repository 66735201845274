@font-face {
  font-family: "Helvetica Now Display";
  src: local("Helvetica Now Display Regular"),
    local("Helvetica-Now-Display-Regular"),
    url("HelveticaNowDisplay-Regular.woff2") format("woff2"),
    url("HelveticaNowDisplay-Regular.woff") format("woff"),
    url("HelveticaNowDisplay-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: local("Helvetica Now Display Medium"),
    local("Helvetica-Now-Display-Medium"),
    url("HelveticaNowDisplay-Medium.woff2") format("woff2"),
    url("HelveticaNowDisplay-Medium.woff") format("woff"),
    url("HelveticaNowDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: local("Helvetica Now Display Extra Bold"),
    local("Helvetica-Now-Display-Extra-Bold"),
    url("HelveticaNowDisplay-ExtraBold.woff2") format("woff2"),
    url("HelveticaNowDisplay-ExtraBold.woff") format("woff"),
    url("HelveticaNowDisplay-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
