.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 8px;
  transform-origin: 0%;
  z-index: 1000000;
}

#weather-app .progress-bar {
  background: var(--weather-bg);
}

#portfolio2021 .progress-bar {
  background: var(--port);
}

#tesla .progress-bar {
  background: var(--tesla);
}

.project-page .header {
  opacity: 1 !important;
}

.container {
  width: 100%;
  padding: 0 40px;
  max-width: 1070px;
  margin: 60px auto;
}

.text-container-1 {
  max-width: 430px;
}
.text-container-2 {
  max-width: 480px;
}
.text-container-3 {
  max-width: 550px;
}
.text-container-4 {
  max-width: 700px;
}

.bold-text {
  font-weight: 600;
}

.project-page .main-img {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 430px;
  border-bottom: solid 1px #000;
  align-items: flex-end;
}

#dark .project-page .main-img {
  border-bottom: solid 1px var(--blue);
}

.project-page #weather-app .main-img {
  background: var(--weather-bg);
}

.project-page #portfolio2021 .main-img {
  background: #000;
}

.project-page #tesla .main-img {
  background: var(--tesla);
}

#dark .project-page #portfolio2021 .main-img {
  background: var(--white);
}

#dark .project-page #tesla .main-img {
  background: var(--white);
}

.project-page .main-img > div {
  height: 100%;
  max-width: 400px;
  display: inline-flex;
}

.project-page #tesla .main-img > div {
  align-items: center;
}

.project-page .main-img img {
  height: auto;
  object-fit: contain;
  cursor: grab;
}

.project-page h3 {
  text-transform: uppercase;
  font-size: 100%;
  margin-top: 0;
}

.summery-text-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 80px;
  row-gap: 40px;
}

.summery-text-container .titles {
  grid-column: 1 / 3;
}

.summery-text-container .titles h1 {
  line-height: 1.6;
  margin: 0 0 15px;
  font-size: 280%;
}

.summery-text-container .titles > a {
  font-size: 100%;
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 10px;
  color: var(--blue);
}

.summery-text-container .titles > a span {
  border-bottom: 1px solid;
  line-height: 1.1;
}

.summery-text-container .titles h4 {
  font-weight: 400;
  font-size: 100%;
}

.summery-text-container .text-block h3 {
  margin-bottom: 24px;
}

.another-project-btn-section .btn-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  margin: 50px auto 32px;
}

.another-project-btn-section .btn {
  font-size: 160%;
  line-height: 1.4em;
  font-weight: 600;
  justify-content: flex-start;
  width: fit-content;
  padding: 1px 0 4px;
  margin: 0 20px;
  background-image: linear-gradient(
    to right,
    #878a8f,
    #878a8f 50%,
    #161e30 50%
  );
  background-size: 200% 100%;
  background-position: 100%;
  display: inline-block;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.4s ease-in-out;
}

#dark .another-project-btn-section .btn {
  background-image: linear-gradient(to right, #878a8f, #878a8f 50%, #fff 50%);
}

.another-project-btn-section .btn:hover {
  background-position: 0;
}

.another-project-btn-section .btn::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: var(--blue);
  transition: 0.4s all;
  cursor: pointer;
}

.another-project-btn-section .btn::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: var(--gray);
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.4s ease-in-out;
  z-index: 1;
  pointer-events: none;
  cursor: pointer;
}

.another-project-btn-section .btn:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

.centered-text {
  text-align: center !important;
}

.margin-top {
  margin-top: 60px;
}

.margin-both {
  margin: 60px 0;
}

.text-with-margin {
  margin-bottom: 24px;
}

.content-divider {
  height: 1px;
  background: #8a8a8a;
  margin: 72px auto;
  width: 72px;
}

.project-page section ul {
  margin: 0;
  padding: 0;
  padding-inline-start: 24px;
}

@media (max-width: 1600px) and (min-width: 767px) {
  .project-page .main-img {
    height: 300px;
  }
  .summery-text-container .titles h1 {
    font-size: 220%;
  }
  .container {
    margin: 40px auto;
  }

  .margin-top {
    margin-top: 40px;
  }

  .margin-both {
    margin: 40px 0;
  }

  .another-project-btn-section .btn-container {
    margin: 40px auto 45px;
  }
}

@media (max-width: 767px) {
  .container {
    padding: 0 20px;
    margin: 30px auto;
  }

  .project-page > section {
    margin-top: 0;
  }

  .project-page .main-img > div span {
    bottom: 10px;
    right: -40px;
    font-size: 70%;
    width: 180px;
    height: 35px;
  }

  .project-page .main-img {
    height: 230px;
    padding-top: 0;
  }

  .project-page #tesla .main-img > div,
  .project-page #portfolio2021 .main-img > div {
    max-width: 200px;
  }

  .summery-text-container {
    display: flex;
    flex-direction: column;
    column-gap: unset;
    row-gap: 24px;
  }

  .summery-text-container .titles h1 {
    line-height: 1.1;
    margin: 0 0 10px;
    font-size: 180%;
  }

  .summery-text-container .titles > a {
    font-size: 90%;
  }

  .summery-text-container .text-block h3 {
    margin-bottom: 8px;
  }

  .project-page h3 {
    font-size: 90%;
  }

  .margin-top {
    margin-top: 30px;
  }

  .content-divider {
    margin: 40px auto;
  }

  .margin-both {
    margin: 30px 0;
  }

  .another-project-btn-section p.centered-text {
    padding: 0 20px;
  }

  .another-project-btn-section .btn-container {
    margin: 30px auto 45px;
    flex-direction: column-reverse;
    align-items: center;
  }
}
